import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../services/user.service'
Vue.use(VueRouter)

const routes = [

 
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },

    {
      path: '/proximamente',
      name: 'Proximamente',
      component: () => import('../views/Proximamente'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },
    {
      path: '/',
      name: 'inicio',
      component: () => import('../views/Home'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },

    {
      path: '/tarimas',
      name: 'tarimas',
      component: () => import('../views/Tarimas'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },
    {
      path: '/permisos-y-certificaciones',
      name: 'Permisos y certificaciones',
      component: () => import('../views/Permisos'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },
    {
      path: '/carretes',
      name: 'carretes',
      component: () => import('../views/Carretes'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },
    {
      path: '/otros-servicios',
      name: 'Otros Servicios',
      component: () => import('../views/OtrosServicios'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },
    {
      path: '/llantera',
      name: 'llantera',
      component: () => import('../views/Llantera'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },
    {
      path: '/bodegas-industriales',
      name: 'bodegas industriales',
      component: () => import('../views/BodegasIndustriales'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },
    {
      path: '/contacto',
      name: 'contacto',
      component: () => import('../views/Contacto'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },
    {
      path: '/madereria',
      name: 'madereria',
      component: () => import('../views/Madereria'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },
    {
      path: '/bolsa-de-trabajo',
      name: 'bolsa de trabajo',
      component: () => import('../views/BolsaTrabajo'),
      meta :{
        log:false,
        admin : false,
        
        auth: false,
      }
    },

  
   

  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/Autentificacion'),
    meta :{
      log:true,
      admin : true,
      
      auth: false
    }
  },
  
  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta :{
      log:true,
      admin : true,
      
      auth: true, 
    }
  },
  

  {
    path: '/aviso-de-privacidad',
    name: 'Aviso',
    component: () => import('../views/AvisoPrivacidad'),
    meta :{
     log:false,
     admin : false,
     
     auth: false
    }
  },

  {
    path: '*',
    name: '404',
    component: () => import('../views/Error404'),
    meta :{
     log:false,
     admin : false,
     
     auth: false
    }
  },


   

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach( async (to, from, next) => {

  let meta_admin = to.matched.some(record=>record.meta.admin);
  let meta_log = to.matched.some(record=>record.meta.log);
  let meta_auth = to.matched.some(record=>record.meta.auth);

  let tokenexp = await userService.tokenexp()
  let check =false //comprobar si esta logeado
  let admin =false;
  let type =''


  if(tokenexp){ // ver que el token no este expirado.
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath') //limpiar el localstorage
    next()
  }


  let response = await userService.getData()

  if(response.status =='error'){
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')
  }


  if(response.status=='success'){ //comprobar que sea un login valido.
    check =true;
    let isAdmin = userService.isAdmin(response.result);
    admin = isAdmin.admin
    type = isAdmin.type

   
   
  
  }

  let ath = await userService.getAth();

 
  // borrar el localstorage cada que vayamos a login.
  
  if(to.path==='/login' && check){
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')
    
    next()
  }

  //redireccionamientos por rol.

  if(!meta_log) next();

  if(meta_log && !check)  next('/login');

  if(meta_admin){
        if(admin){
        
          if(meta_auth){
            if(ath){
              next();
            }else{
              localStorage.removeItem('tkn')
              localStorage.removeItem('ath')
              next('/login')
            }
          }else{ //...proximamente rutas que no requieran autentificacion
            next()
          }
          
        }else{
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/')
        }
  }else{
    next()
  }

 
});




export default router

