<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Switzer';
  src: url("./assets/fonts/Switzer-Variable.ttf");
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #0055b8;
  --color-2: #f93549;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #a7a7a7;
  --color-6: #d9d9d9;
  --color-7: #ffffff;


  --background: #0055b8;
  --uno: #0055b8;
  --dos: #f93549;
  --tres: #ffc844;
  --opacity: rgba(255, 255, 255, 0.6);


  --dmsans: "DM Sans", sans-serif;
  --DMSans: "DM Sans", sans-serif;

}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}

.dflxc {
  display: flex;
  justify-content: center;
}

.dflxr {
  display: flex;
  justify-content: right;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

p.chpass {
  color: #3c3c3c;
  font-family: 'DM Sans';
  margin: 0vw;
  margin-bottom: .2vw;
  font-size: .8vw;
}

.mB3F4 .ql-toolbar.ql-snow {
  display: none;
}

.mB3F4 .ql-editor {
  resize: none;
  width: 21.858333vw;
  height: 10.241667vw;
  min-block-size: fit-content;
  background-color: #f9f9f9;
  border: none;
  font-size: 0.625vw;
  line-height: 0.8135416666666666vw;
  font-weight: 400;
  font-family: var(--dmsans);
  margin: auto;
  padding: 0.8333333333333334vw 0vw;
}

.mB3F4 .ql-container {
  border: none !important;
}

.dflx {
  display: flex;
}

.dflxa {
  display: flex;
  align-items: center;
}

.dflxca {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dflxsp {
  display: flex;
  justify-content: space-between;
}

.dflxfe {
  display: flex;
  justify-content: flex-end;
}

.mrl05 {
  margin-left: 0.5vw !important;
  margin-top: 0vw !important;
  margin-bottom: 0vw !important;
}

.porel {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crs_ptr {
  cursor: pointer;
}

.active_color {
  background-color: var(--color-1) !important;
  color: #f5f5f5 !important;
}


/* formulario respuesta */

.editor_frm_res {
  width: 41.458vw;
  margin-top: 1.198vw;
  margin-bottom: 1.406vw;
  margin-left: 2.188vw;
}

.editor_frm_res .ql-toolbar {
  border-radius: 0.521vw 0px 0px 0px !important;
}

.editor_frm_res .ql-container {

  border-radius: 0vw !important;

}

/* fin formulario respuesta */

.editor_frm_pre {
  width: 37.72vw;
  margin-top: 0.417vw;
  margin-bottom: 1.406vw;
}

.editor_frm_pre .ql-toolbar {
  border-radius: 0.521vw 0px 0px 0px !important;
}

.editor_frm_pre .ql-container {

  border-radius: 0vw !important;

}

p.ttl_nv {
  color: #242424;
  font-weight: 700;
  font-size: 0.7291666666666666vw;
  margin-bottom: 0.3vw;
  font-family: 'DM Sans';
  margin-bottom: .5vw;
}

.opt_nv {
  display: flex;
  align-items: center;
  margin-right: 1vw;
  padding: 0vw .5vw .2vw .5vw;
  cursor: pointer;
  border-bottom: .2vw solid transparent;
  transition: all 300ms;
}

.opt_nv:hover {
  border-bottom: .2vw solid #0055b8;
}

.hover_disable:hover {
  border-bottom: .2vw solid transparent !important;
}

.opt_nv img {
  margin-right: .5vw;
  width: .8vw;
  height: .8vw;
}

.opt_nv p {
  margin: 0vw;
  font-family: 'DM Sans';
  color: #3c3c3c;
  font-size: .8vw;
}

.opt_nv p.sel {

  font-weight: 600;
}

.opts_sec {
  width: 97%;
  display: flex;
  justify-content: right;
  margin-bottom: 2vw;
}

.opts_sec a {
  margin-left: 1vw;
  background-color: #F93549;
  color: white;
  padding: .5vw 1vw;
  cursor: pointer;
  text-decoration: none;
  font-family: 'DM Sans';
  letter-spacing: .1vw;
  border-radius: 5vw;
}

/* Bolsa de trabajo */

.oferta__info span p {
  display: inline;
  margin: 0;
}

.oferta__info .actividades,
.oferta__info .experiencia,
.oferta__info .otros {
  display: grid;
}

.oferta__info .actividades>*,
.oferta__info .experiencia>*,
.oferta__info .otros>* {
  margin: 0;
  padding: 0;
  font-weight: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
  list-style: none !important;
  display: grid !important;
  font-family: inherit !important;
}

@media (max-width: 768px) {
  html {
    scroll-padding-top: 38.48115942028985VW;
  }

  .oferta__info .actividades>*,
  .oferta__info .experiencia>*,
  .oferta__info .otros>* {
    row-gap: 3px;
  }
}

@media (min-width: 768px) {
  html {
    scroll-padding-top: 10.316666666666668VW;
  }

  .oferta__info .actividades>*,
  .oferta__info .experiencia>*,
  .oferta__info .otros>* {
    row-gap: 3px;
  }
}
</style>
