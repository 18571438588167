import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import copy from './modules/copy'
import logo from './modules/logo'
import main from './modules/main'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import suscripciones from './modules/suscripciones'
import vacantes from './modules/vacantes'




Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    main: main,
    usuarios: usuarios,
    profile:profile,
    colors:colors,
    logo:logo,
    copy:copy,
    suscripciones:suscripciones,
    vacantes:vacantes,
  }
})
